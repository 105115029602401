import { ColorPicker, InputNumber } from 'antd';
import { FC } from 'react';
import { PiRoadHorizon } from 'react-icons/pi';

import { NiraSetup } from '../../hooks/apiNIRA/useGetNiraVector';

interface IControls {
  setup: NiraSetup;
  edit: (val: NiraSetup) => void;
  collapsed: boolean;
}

export const NiraControls: FC<IControls> = ({ setup, edit, collapsed }) => {
  return (
    <div
      className={`pointer-events-auto rounded-md ${
        setup.enabled ? 'bg-[#081B37]' : 'bg-[#B5B5B5]'
      }`}
    >
      <div
        className={`whitespace-nowrap flex pointer-events-auto overflow-hidden items-center cursor-pointer gap-3 px-4 py-2 text-xl text-white rounded-md shadow-sm transition w-full`}
        onClick={() => edit({ ...setup, enabled: !setup.enabled })}
      >
        <span className="anticon">
          <PiRoadHorizon />
        </span>
        <span className="label">NIRA Friction</span>
      </div>
      <div
        className={`${
          collapsed ? 'h-full' : 'h-0'
        } w-[calc(100%-33px)] ml-auto px-4 text-white rounded-b-md overflow-hidden`}
      >
        <div className="grid grid-cols-5 w-full items-center gap-2">
          <div className="col-span-3">Danger Color</div>
          <div className="col-span-2">
            <ColorPicker
              placement="topRight"
              size="small"
              value={setup.dangerColor}
              onChange={(color) => edit({ ...setup, dangerColor: color.toHexString() })}
            />
          </div>
          <div className="col-span-3">Warn Color</div>
          <div className="col-span-2">
            <ColorPicker
              placement="topRight"
              size="small"
              value={setup.warnColor}
              onChange={(color) => edit({ ...setup, warnColor: color.toHexString() })}
            />
          </div>
          <div className="col-span-3">Low Risk Color</div>
          <div className="col-span-2">
            <ColorPicker
              placement="topRight"
              size="small"
              value={setup.lowRiskColor}
              onChange={(color) => edit({ ...setup, lowRiskColor: color.toHexString() })}
            />
          </div>
        </div>
        <div className="grid grid-cols-5 w-full items-center gap-2 py-2">
          <div className="col-span-3">Stroke Width</div>
          <div>
            <InputNumber
              size="small"
              value={setup.strokeWidth}
              onChange={(value) =>
                typeof value === 'number' && edit({ ...setup, strokeWidth: value })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
