import { Overlay } from 'ol';
import MVT from 'ol/format/MVT';
import VectorTileLayer from 'ol/layer/VectorTile';
import Map from 'ol/Map';
import VectorTileSource from 'ol/source/VectorTile';
import { Fill, Stroke, Style } from 'ol/style';
import { useEffect } from 'react';

import { NIRA_ACCESS_TOKEN, NIRA_URL } from '../../variables/nira';

export interface NiraSetup {
  enabled: boolean;
  strokeWidth: number;
  dangerColor: string;
  warnColor: string;
  lowRiskColor: string;
}
interface NiraVectorLayerProps {
  map: Map | null;
  setup: NiraSetup;
}
export const useGetNiraVector = ({ map, setup }: NiraVectorLayerProps) => {
  const { enabled, dangerColor, warnColor, lowRiskColor, strokeWidth } = setup;
  useEffect(() => {
    if (!map) return;
    const vectorTileLayer = new VectorTileLayer({
      source: new VectorTileSource({
        format: new MVT(),
        url: NIRA_URL + NIRA_ACCESS_TOKEN,
        cacheSize: Infinity,
      }),
      className: 'NIRA',
      minZoom: 10,
      style: (feature) => {
        const properties = feature.getProperties();
        const frictionAvg: number = properties['Friction-Average'] || 1;
        const color =
          frictionAvg <= 0.5 ? dangerColor : frictionAvg <= 0.8 ? warnColor : lowRiskColor;
        return new Style({
          fill: new Fill({
            color: color,
          }),
          stroke: new Stroke({
            color: color,
            width: strokeWidth,
          }),
        });
      },
      zIndex: 100,
    });
    if (map && enabled) map.addLayer(vectorTileLayer);
    const tooltipElement = document.createElement('div');
    tooltipElement.className = 'tooltip';
    tooltipElement.style.position = 'absolute';
    tooltipElement.style.background = 'white';
    tooltipElement.style.border = '1px solid black';
    tooltipElement.style.padding = '5px';
    tooltipElement.style.display = 'none'; // Initially hidden
    document.body.appendChild(tooltipElement);
    const overlays = map
      .getOverlays()
      ?.getArray()
      ?.filter((o) => o && o.getId() === 'nira-data-overlay');
    overlays?.forEach((o) => {
      map.removeOverlay(o);
      o.dispose();
    });

    const tooltipOverlay = new Overlay({
      element: tooltipElement,
      offset: [10, 0],
      positioning: 'bottom-left',
      id: 'nira-data-overlay',
    });
    enabled && map.addOverlay(tooltipOverlay);
    map.on('pointermove', (event) => {
      let foundFeature = false;

      map.forEachFeatureAtPixel(event.pixel, (feature) => {
        if (feature.getProperties().layer === 'nira-maintenance-aggregation') {
          const properties = feature.getProperties();

          tooltipElement.innerHTML = `<div class="flex flex-col">
              <h4 style="font-weight: bold; font-size: 1.2rem">Friction</h4>
              <div style="white-space: nowrap">Confidence: ${properties.Confidence} </div>
              <div>Average: ${properties['Friction-Average']}</div>
              <div  style="white-space: nowrap">Average Standard Deviation: ${properties['Friction-Average-StandardDeviation']}</div>
              <div>Max: ${properties['Friction-Max']}</div>
              <div>Min: ${properties['Friction-Min']}</div>
              <br/>
              <div>Functional Road Class: ${properties.FunctionalRoadClass}</div>
              <div>Highest TGP Weight: ${properties.HighestTGPWeight}</div>
              <div>Number Of TGPs: ${properties.NumberOfTGPs}</div>
              <div>Nvdb Road Class: ${properties.NvdbRoadClass}</div>
              </div>
              </div>`;
          tooltipElement.style.display = 'block';
          tooltipOverlay.setPosition(event.coordinate);
          foundFeature = true;
          return true;
        }
      });

      if (!foundFeature) {
        tooltipElement.style.display = 'none';
      }
    });
    if (!enabled) {
      map.removeLayer(vectorTileLayer);
    }
    const intervalId = setInterval(() => {
      if (enabled && vectorTileLayer) {
        const source = new VectorTileSource({
          format: new MVT(),
          url: NIRA_URL + NIRA_ACCESS_TOKEN + `&timestamp=${new Date().getTime()}`, // Cache busting with timestamp
        });
        vectorTileLayer.setSource(source);
      }
    }, 60000); // Refresh every 60 seconds
    return () => {
      if (map && vectorTileLayer) {
        map.removeLayer(vectorTileLayer);
      }
      clearInterval(intervalId);
    };
  }, [dangerColor, lowRiskColor, warnColor, enabled, map, strokeWidth]);
};
