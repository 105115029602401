import { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';

import { animate } from '../../animations/animate';
import { ModeEnum } from '../../core/ui/enums/ModeEnum';
import { getAnimationType } from '../../helpers/timelineUtil';
import { MAX_FULLSCREEN_HEIGHT } from '../../model/constants/constants';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';
import { ElementContainerInterface } from './ElementContainer';
import style from './style.module.scss';
import { transformPercentToAbsolute } from './utils';

export const TimeIndicatorContainer = ({
  canvas,
  panelProps,
  disabled,
  type,
  lock = true,
  children,
  parentTime,
  inPoster,
  posterId,
  parentSize,
  isMapOverlay = false,
  parentMapId,
  geoPosterId,
  mainIndicator,
  sceneId,
  ...rest
}: ElementContainerInterface) => {
  const elementContainerRef = useRef(null);
  const contextValue = useContext(PlayerContext);
  const { time } = contextValue;
  // @ts-ignore
  // @ts-ignore
  const { mode, activeAspectRatio, projectToPlay } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  const { positionControl, id, timeControls } = panelProps;
  const elementTime = timeControls;
  const scene = projectToPlay?.sceneDefs.find((scene) => scene.id === sceneId);
  const sceneTime = projectToPlay.sceneDefs.find((scene) => scene.id === sceneId)?.timeControl;
  const isSceneEnd = timeControls?.[0].endMS + (scene?.hold || 0) === sceneTime?.endMS;
  const run = () => {
    if (!parentTime)
      return elementTime.find(
        (segment) =>
          segment.startMS <= time && segment.endMS + (isSceneEnd ? scene?.hold ?? 0 : 0) >= time,
      );
    if (isMapOverlay)
      return parentTime.find((segment) => segment.startMS <= time && segment.endMS >= time);

    return elementTime.find(
      (segment) => segment.startMS + parentTime[0].startMS <= time && segment.endMS >= time,
    );
  };

  const isVisible = () => (!run() ? 'hidden' : 'visible');

  const translateAbsolute = {
    x: transformPercentToAbsolute(
      positionControl?.x,
      activeAspectRatio,
      'width',
      MAX_FULLSCREEN_HEIGHT,
      {
        w: inPoster ? parentSize?.w : undefined,
        h: inPoster ? parentSize?.h : undefined,
      },
    ),
    y: transformPercentToAbsolute(
      positionControl?.y,
      activeAspectRatio,
      'height',
      MAX_FULLSCREEN_HEIGHT,
      {
        w: inPoster ? parentSize?.w : undefined,
        h: inPoster ? parentSize?.h : undefined,
      },
    ),
  };
  return (
    <>
      <div
        data-parent={posterId}
        data-parenttype={posterId && 'weatherPosters'}
        data-type={type}
        data-id={id}
        ref={elementContainerRef}
        style={{
          position: 'absolute',
          width: transformPercentToAbsolute(
            positionControl?.w,
            activeAspectRatio,
            'width',
            MAX_FULLSCREEN_HEIGHT,
            {
              w: inPoster ? parentSize?.w : undefined,
              h: inPoster ? parentSize?.h : undefined,
            },
          ),
          height: transformPercentToAbsolute(
            positionControl?.h,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
            {
              w: inPoster ? parentSize?.w : undefined,
              h: inPoster ? parentSize?.h : undefined,
            },
          ),
          transform: `translate(${translateAbsolute.x}px, ${translateAbsolute.y}px) rotate(${(
            positionControl?.rotation || 0
          ).toFixed(4)}deg)`,
          opacity: 1,
          visibility: isVisible(),
          zIndex: panelProps.positionControl.zindex,
          textAlign: 'center',
          boxSizing: 'border-box',
          ...rest.style,
        }}
        className={`${style.canvasElement} ${
          mode === ModeEnum.SEQUENCE && !disabled ? style.elementHover : null
        }`}
      >
        {animate(
          children,
          getAnimationType(run()?.inAnimationDef),
          {
            duration: run()?.inAnimationDuration,
            enterTime: run()?.startMS,
            currentTime: contextValue.time,
          },
          getAnimationType(run()?.outAnimationDef),
          {
            duration: run()?.outAnimationDuration,
            exitTime: run()?.endMS,
            currentTime: contextValue.time,
          },
        )}
      </div>
    </>
  );
};
